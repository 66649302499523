<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- rent settings -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>
            {{ $t('components.settingsManagement.rent.headline.rentSettings') }}
          </span>
        </div>
        <div :key="`rentSettings_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in rentSettings">
            <template v-if="item.type === 'toggle'">
              <SmartToggleInput
                :key="itemIndex"
                :label="item.label"
                :description="item.description"
                :onText="item.onText"
                :offText="item.offText"
                :onValue="item.onValue"
                :offValue="item.offValue"
                v-model="rent[`${item.variable}`]"
                @save="onSave('rent', `${item.variable}`, $event)"
              />
            </template>
            <template v-else>
              <SmartAppInput
                v-if="item.variable === 'rent_duration_mode'"
                :key="itemIndex"
                :rowId="`rent_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :options="rentDurationModeChoices"
                :isEdit="item.isEdit"
                v-model="rent[`${item.variable}`]"
                @save="onSave('rent', `${item.variable}`, $event)"
              />

              <SmartAppInput
                v-else-if="item.variable === 'rent_vehicle_return_area_policy'"
                :key="itemIndex"
                :rowId="`rent_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :options="rentVehicleReturnChoices"
                :isEdit="item.isEdit"
                v-model="rent[`${item.variable}`]"
                @save="onSave('rent', `${item.variable}`, $event)"
              />
              <SmartAppInput
                v-else-if="item.variable === 'rent_vehicle_assignment_policy'"
                :key="itemIndex"
                :rowId="`rent_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :options="rentVehicleAssignedChoices"
                :isEdit="item.isEdit"
                v-model="rent[`${item.variable}`]"
                @save="onSave('rent', `${item.variable}`, $event)"
              />
              <SmartAppInput
                v-else
                :key="itemIndex"
                :rowId="`rent_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :isEdit="item.isEdit"
                v-model="rent[`${item.variable}`]"
                :haveUnitText="item.haveUnitText"
                :unitText="item.unitText"
                @save="onSave('rent', `${item.variable}`, $event)"
              />
            </template>
          </template>
        </div>
      </div>

      <!-- rent workflow settings -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>
            {{
              $t(
                'components.settingsManagement.rent.headline.rentWorkflowSettings'
              )
            }}
          </span>
        </div>
        <div
          :key="`rentWorkflowSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in rentWorkflowSettings">
            <SmartToggleInput
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="rent[`${item.variable}`]"
              @save="onSave('rent', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils'
export default {
  name: 'RentSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      rentDurationModeChoices: [],
      rentVehicleReturnChoices: [],
      rentVehicleAssignedChoices: [],
      rent: {
        minimum_rent_duration_in_hours: null,
        rent_duration_mode: null,
        rent_vehicle_return_area_policy: null,
        rent_vehicle_assignment_policy: null,
        use_google_maps_api_for_rent_location: null,
        rent_time_extension_enabled: null,
        should_start_rent_after_accepting: null,
        is_rental_location_input_required: null,
        is_rental_time_input_required: null,
      },

      rentSettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.rent.rent.minimumRentDuration.title'
          ),
          placeholder: 'e.g 1',
          description: this.$t(
            'components.settingsManagement.rent.rent.minimumRentDuration.description'
          ),
          variable: 'minimum_rent_duration_in_hours',
          haveUnitText: true,
          unitText: 'Hours',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.rent.rent.rentDurationMode.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rent.rentDurationMode.description'
          ),
          variable: 'rent_duration_mode',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.rent.rent.vehicleRentReturnPolicy.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rent.vehicleRentReturnPolicy.description'
          ),
          variable: 'rent_vehicle_return_area_policy',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.rent.rent.vehicleRentAssignedPolicy.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rent.vehicleRentAssignedPolicy.description'
          ),
          variable: 'rent_vehicle_assignment_policy',
          isEdit: false,
        },
      ],
      rentWorkflowSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.rent.rentWorkflow.ridersFreedomInRentLocationSelection.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rentWorkflow.ridersFreedomInRentLocationSelection.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'use_google_maps_api_for_rent_location',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.rent.rentWorkflow.enableRentExtraTimeExtension.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rentWorkflow.enableRentExtraTimeExtension.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'rent_time_extension_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.rent.rentWorkflow.allowRiderToStartRentAfterAdminApproval.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rentWorkflow.allowRiderToStartRentAfterAdminApproval.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_start_rent_after_accepting',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.rent.rentWorkflow.locationInputRequired.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rentWorkflow.locationInputRequired.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_rental_location_input_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.rent.rentWorkflow.rentalTimeInputRequired.title'
          ),
          description: this.$t(
            'components.settingsManagement.rent.rentWorkflow.rentalTimeInputRequired.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_rental_time_input_required',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    const rentReq = this.$http.get(useEndpoints.settings.rent.index(this.orgId))
    const rentModeChoicesReq = this.$http.get(
      useEndpoints.settings.rent.rentDurationModeChoices()
    )
    const rentVehicleReturnAreaChoicesReq = this.$http.get(
      useEndpoints.settings.rent.rentVehicleReturnAreaChoices()
    )
    const vehicleRentAssignedPolicyChoicesReq = this.$http.get(
      useEndpoints.settings.rent.vehicleRentAssignedPolicyChoices()
    )

    const requests = [
      rentReq,
      rentModeChoicesReq,
      rentVehicleReturnAreaChoicesReq,
      vehicleRentAssignedPolicyChoicesReq,
    ]
    await this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.isLoaded = true
        this.rent.minimum_rent_duration_in_hours =
          responses[0].data.minimum_rent_duration_in_hours
        this.rent.rent_duration_mode = responses[0].data.rent_duration_mode
        this.rent.rent_vehicle_return_area_policy =
          responses[0].data.rent_vehicle_return_area_policy
        this.rent.rent_vehicle_assignment_policy =
          responses[0].data.rent_vehicle_assignment_policy
        this.rent.use_google_maps_api_for_rent_location =
          responses[0].data.use_google_maps_api_for_rent_location
        this.rent.rent_time_extension_enabled =
          responses[0].data.rent_time_extension_enabled
        this.rent.should_start_rent_after_accepting =
          responses[0].data.should_start_rent_after_accepting
        this.rent.is_rental_location_input_required =
          responses[0].data.is_rental_location_input_required
        this.rent.is_rental_time_input_required =
          responses[0].data.is_rental_time_input_required

        this.rentDurationModeChoices = responses[1].data
        this.rentVehicleReturnChoices = responses[2].data
        this.rentVehicleAssignedChoices = responses[3].data
      })
    )
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'rent') {
        this.rentSettings = this.rentSettings.map((item, itemIndex) => {
          if (id === `rent_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'rent'
      }
    })
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'rent') {
          this.rentSettings = this.rentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  methods: {
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'rent') {
        url = useEndpoints.settings.rent.update(this.orgId)
      }

      let data = new FormData()
      data.append(key, val)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.rentSettings = this.rentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Update Successfully',
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang=""></style>
